export const ADD_SVG = 'ADD_SVG';
export const ADD_SVG_SUCCESS = 'ADD_SVG_SUCCESS';
export const ADD_SVG_FAILED = 'ADD_SVG_FAILED';

export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAIL = 'ADD_TEAM_FAIL';

export const DELETE_ELEMENT = 'DELETE_ELEMENT';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED';

export const SET_TEAM_ACTIVE = 'SET_TEAM_ACTIVE';
export const ACTIVATE_TEAM = 'ACTIVATE_TEAM';
export const ACTIVATE_TEAM_SUCCESS = 'ACTIVATE_TEAM_SUCCESS';
export const ACTIVATE_TEAM_FAILED = 'ACTIVATE_TEAM_FAILED';
export const UPDATE_ACTIVE_TEAM_INDEX = 'UPDATE_ACTIVE_TEAM_INDEX';

export const CHANGE_TEAM_COLOR = 'CHANGE_TEAM_COLOR';
export const CHANGE_TEAM_COLOR_SUCCESS = 'CHANGE_TEAM_COLOR_SUCCESS';
export const CHANGE_TEAM_COLOR_FAILED = 'CHANGE_TEAM_COLOR_FAILED';

export const CHANGE_TEAM_NAME = 'CHANGE_TEAM_NAME';
export const CHANGE_TEAM_NAME_SUCCESS = 'CHANGE_TEAM_SUCCESS';
export const CHANGE_TEAM_NAME_FAILED = 'CHANGE_TEAM_FAILED';

export const UPDATE_SURFACE_AREA = 'UPDATE_SURFACE_AREA';
export const UPDATE_SURFACE_AREA_SUCCESS = 'UPDATE_SURFACE_AREA_SUCCESS';
export const UPDATE_SURFACE_AREA_FAILED = 'UPDATE_SURFACE_AREA_FAILED';

export const UPDATE_FLEX_FACTOR = 'UPDATE_FLEX_FACTOR';
export const UPDATE_FLEX_FACTOR_SUCCESS = 'UPDATE_FLEX_FACTOR_SUCCESS';
export const UPDATE_FLEX_FACTOR_FAILED = 'UPDATE_FLEX_FACTOR_FAILED';

export const SET_SPACE_NORMS = 'SET_SPACE_NORMS';
export const FETCH_SPACE_NORMS = 'FETCH_SPACE_NORMS';
export const FETCH_SPACE_NORMS_SUCCESS = 'FETCH_SPACE_NORMS_SUCCESS';
export const FETCH_SPACE_NORMS_FAILED = 'FETCH_SPACE_NORMS_FAILED';

export const SET_TEAMS = 'SET_TEAMS';
export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';

export const SAVE_PDF_SUCCESS = 'SAVE_PDF_SUCCESS';
export const SAVE_PDF_FAILED = 'SAVE_PDF_FAILED';

export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_FAILED = 'FETCH_PDF_FAILEDs';

export const SAVE_SCALE = 'SAVE_SCALE';
export const SAVE_SCALE_SUCCESS = 'SAVE_SCALE_SUCCESS';
export const SAVE_SCALE_FAILED = 'SAVE_SCALE_FAILED';

export const GET_SCALE_SUCCESS = 'GET_SCALE_SUCCESS';
export const GET_SCALE_FAILED = 'GET_SCALE_FAILED';

export const SAVE_DISTANCE = 'SAVE_DISTANCE';

export const GET_REFERENCE_SIZE = 'GET_REFERENCE_SIZE'
export const GET_REFERENCE_SIZE_SUCCESS = 'GET_REFERENCE_SIZE_SUCCESS'
export const GET_REFERENCE_SIZE_FAILED = 'GET_REFERENCE_SIZE_FAILED'

export const SAVE_REFERENCE_SIZE_SUCCESS = 'SAVE_REFERENCE_SIZE_SUCCESS'
export const SAVE_REFERENCE_SIZE_FAILED = 'SAVE_REFERENCE_SIZE_FAILED'
export const UPDATE_REFERENCE_SIZE_SUCCESS = 'UPDATE_REFERENCE_SIZE_SUCCESS'
export const UPDATE_REFERENCE_SIZE_CLOUD = 'UPDATE_REFERENCE_SIZE_CLOUD'

export const GET_PDF_FILE_SUCCESS = 'GET_PDF_FILE_SUCCESS';

export const GET_PDF_IMAGE_SUCCESS = 'GET_PDF_IMAGE_SUCCESS';

export const SAVE_CANVAS_SUCCESS = 'SAVE_CANVAS_SUCCESS';

export const POINTS = 'POINTS';

export const SAVE_TEMP_POINTS = 'SAVE_TEMP_POINTS';

export const SET_DRAW_MODE = 'SET_DRAW_MODE';

export const SET_ELEMENT_TYPE = 'SET_ELEMENT_TYPE';
export const UPDATE_SCALE = 'UPDATE_SCALE';
export const UPDATE_DISTANCE = 'UPDATE_DISTANCE';
export const UPDATE_REFERENCE_SIZE = 'UPDATE_REFERENCE_SIZE';

export const ADD_ELEMENT = 'ADD_ELEMENT';
export const SET_ELEMENTS = 'SET_ELEMENTS';

export const SHOW_TOOLBAR = 'SHOW_TOOLBAR';
