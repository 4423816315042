import React from 'react';

import Colors from '../TeamControls/Colors/Colors';

import deleteIcon from '../../../assets/img/delete.svg';

const Team = (props) => {
    return (
        <div
            id={'team_' + props.team.id}
            className="team"
            data-team-id={props.team.id}
        >
            <label
                id={'team__name_' + props.team.id}
                className="team__name"
                data-team-id={props.team.id}
                style={{ backgroundColor: props.team.color }}
                onClick={() => props.activeStateHandler(props.team.id)}
            >
                {props.team.name}
            </label>
            <img
                className="team__delete"
                src={deleteIcon}
                alt="delete"
                onClick={() => props.deleteTeamHandler(props.team.id)}
            />

            <div
                className="team__details"
                style={props.active ? {} : { display: 'none' }}
            >
                <div className="team__info">
                    <label className="team__details-name">Naam: </label>
                    <input
                        className="details__input"
                        type="text"
                        onChange={(e) =>
                            props.valChange(e.target.value)
                        }
                        value={props.team.name}
                        data-team-id={props.team.id}
                    />
                </div>

                <Colors id={props.team.id} colorClick={props.colorClick} />
            </div>
        </div>
    );
};

export default Team;
