import React from 'react';

const Colors = (props) => {
    const colors = [
        '#FD3131',
        '#FDB131',
        '#316FFD',
        '#008F1A',
        '#AF0359',
        '#8E4F0F',
    ];

    const colorComponents = colors.map((color, index) => {
        return (
            <div className="palette__color" key={index} data-team-id={props.id}>
                <button
                    style={{ background: color }}
                    data-color={color}
                    onClick={() => props.colorClick(color)}
                ></button>
            </div>
        );
    });

    return <div className="palette">{colorComponents}</div>;
};

export default Colors;
