import * as actionTypes from './actionTypes';
import axios from '../../axios-floors';
// import { v4 as uuidv4 } from 'uuid';

export const setTeams = (teams) => {
    return {
        type: actionTypes.SET_TEAMS,
        teamData: teams
    };
};

export const addTeam = (team) => {
    return (dispatch, getState) => {
        axios.post('/teams.json', team)
        .then(response => {
            dispatch(addTeamSuccess(response.data, team));
        })
        .catch(error => {
            dispatch(addTeamFail(error));
        });
    }  
};

export const setDrawMode = (drawMode) => {
    return {
        type: actionTypes.SET_DRAW_MODE,
        drawMode
    }
}

export const addTeamSuccess = (id, teamData) => {
    return {
        type: actionTypes.ADD_TEAM_SUCCESS,
        teamId: id,
        teamData: teamData
    };
};

export const addTeamFail = (error) => {
    return {
        type: actionTypes.ADD_TEAM_FAIL,
        error: error
    };
};

export const addFloorPoint = (teams, point) => {
    return (dispatch, getState) => {
        axios.put('/teams.json', teams)
        .then(response => {
            dispatch(addFloorPointSuccess(response.data));
        })
        .catch(error => {
            dispatch(addFloorPointFailed(error));
        })
    };
};

export const addFloorPointSuccess = (teamData) => {
    return {
        type: actionTypes.ADD_SVG_SUCCESS,
        teamData: teamData
    };
};

export const addFloorPointFailed = (error) => {
    return {
        type: actionTypes.ADD_SVG_FAILED,
        error: error
    };
};

export const activateTeam = (team) => {
    return (dispatch, getState) => {
        const updatedTeams = getState().floorPlannerBuilder.teams.map(el => {
            return (
                el.id === team
                ? { ...el, active: true }
                : { ...el, active: false }
            )
        });

        let updateActiveStates = {};
        getState().floorPlannerBuilder.teams.map(el =>
            el.id === team
            ? updateActiveStates[el.id+'/active'] = true
            : updateActiveStates[el.id+'/active'] = false);

        axios.patch('/teams.json', updateActiveStates)
        .then(response => {
            dispatch(activateTeamSuccess(response.data.name, team, updatedTeams));
        })
        .catch( error => {
            dispatch(activateTeamFailed(error));
        });
    };
};

// export const updateActiveTeamIndex = (teamId) => {
//     return {
//         type: actionTypes.UPDATE_ACTIVE_TEAM_INDEX,
//         teamId: teamId
//     }
// }

export const activateTeamSuccess = (id, teamId, teamData) => {
    return {
        type: actionTypes.ACTIVATE_TEAM_SUCCESS,
        teamId: id,
        teamData: teamData
    };
};

export const activateTeamFailed = (error) => {
    return {
        type: actionTypes.ACTIVATE_TEAM_FAILED,
        error: error
    };
};

export const changeTeamName = (id, name) => {
    return (dispatch, getState) => {
        const updatedTeams = getState().floorPlannerBuilder.teams.map(el => {
            return (
                el.id === id
                ? { ...el, name: name }
                : { ...el }
            )
        });

        axios.patch('/teams.json', {[id+'/name']:name})
        .then(response => {
            dispatch(changeTeamNameSuccess(response.data, updatedTeams));
        })
        .catch( error => {
            dispatch(changeTeamNameFailed(error));
        });
    };
};

export const changeTeamNameSuccess = (id, teamData) => {
    return {
        type: actionTypes.CHANGE_TEAM_NAME_SUCCESS,
        teamId: id,
        teamData: teamData
    };
};

export const changeTeamNameFailed = (error) => {
    return {
        type: actionTypes.CHANGE_TEAM_NAME_FAILED,
        error: error
    };
};

export const changeTeamColor = (id, color) => {
    return (dispatch, getState) => {
        const updatedTeams = getState().floorPlannerBuilder.teams.map(el => {
            return (
                el.id === id
                ? { ...el, color: color }
                : { ...el }
            )
        });

        axios.patch('/teams.json', {[id+'/color']:color})
        .then(response => {
            dispatch(changeTeamColorSuccess(response.data, updatedTeams));
        })
        .catch(error => {
            dispatch(changeTeamColorFailed(error));
        });
    };
};

export const changeTeamColorSuccess = (id, teamData) => {
    return {
        type: actionTypes.CHANGE_TEAM_COLOR_SUCCESS,
        teamId: id,
        teamData: teamData
    };
};

export const changeTeamColorFailed = (error) => {
    return {
        type: actionTypes.CHANGE_TEAM_COLOR_FAILED,
        error: error
    };
};

export const syncDeleteTeam = (teams) => {
    return {
        type: actionTypes.DELETE_TEAM,
        teamData: teams
    };
};

export const deleteTeamSuccess = (teams) => {
    return {
        type: actionTypes.DELETE_TEAM_SUCCESS,
        teamData: teams
    };
};

export const deleteTeamFailed = () => {
    return {
        type: actionTypes.DELETE_TEAM_FAILED
    };
};

export const deleteTeam = (id) => {
    return (dispatch, getState) => {
        const updatedTeams = getState().floorPlannerBuilder.teams.filter(
            (el) => el.id !== id
        );
        axios.delete('/teams/'+id+'.json')
        .then(response => {
            dispatch(deleteTeamSuccess(updatedTeams));
        })
        .catch(error => {
        });
    };
};

export const fetchTeamsSuccess = (teams) => {
    return {
        type: actionTypes.FETCH_TEAMS_SUCCESS,
        teamData: teams
    };
};

export const fetchTeamsStart = () => {
    return {
        type: actionTypes.FETCH_TEAMS_START
    };
};

export const fetchTeams = () => {
    return dispatch => {
        // let fetched = [];

        axios.get('/teams.json')
            .then(response => {
                let fetchedTeams = [];
                for (let key in response.data) {
                    fetchedTeams.push({
                        ...response.data[key],
                        id: key
                    });
                }

                dispatch(fetchTeamsSuccess(fetchedTeams));
            })
            .catch(error => {
                dispatch(fetchTeamsFailed());
            });
    };
};

export const fetchTeamsFailed = () => {
    return {
        type: actionTypes.FETCH_TEAMS_FAILED
    };
};

export const saveCanvas = (canvas) => {
    return {
        type: actionTypes.SAVE_CANVAS_SUCCESS,
        canvas
    }
}