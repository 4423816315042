import React from 'react';
import { withRouter } from 'react-router-dom';

import Aux from '../../hoc/Aux/Aux';

const Navigation = (props) => {
    return (
        <Aux>
            <div className="navigation">
                <button
                    onClick={props.nextClickHandler}
                    className={"btn btn--blue " + (props.disabled ? 'disabled' : '')}
                    disabled={props.disabled ? 'disabled' : ''}
                >
                    {props.btnNext}
                </button>
                
                <button className="go-back" onClick={props.history.goBack}>vorige stap</button>
            </div>
        </Aux>
    );
}

export default withRouter(Navigation);