import * as actionTypes from './actionTypes';
import axios from '../../axios-floors';

export const setElementType = (elementType) => {
    return {
        type: actionTypes.SET_ELEMENT_TYPE,
        elementType
    }
}

export const saveReferenceSize = (referenceSize) => {
    return {
        type: actionTypes.UPDATE_REFERENCE_SIZE,
        referenceSize
    }
};

export const setDrawMode = (drawMode) => {
    return {
        type: actionTypes.SET_DRAW_MODE,
        drawMode
    }
}

export const saveReferenceSizeSuccess = (referenceSize) => {
    return {
        type: actionTypes.UPDATE_REFERENCE_SIZE_CLOUD,
        referenceSize
    }
}

export const saveReferenceSizeFailed = () => {
    return {
        type: actionTypes.SAVE_REFERENCE_SIZE_FAILED
    }
}

export const getReferenceSize = () => {
    return (dispatch) => {
        axios.get('/reference-size.json')
            .then(response => {
                dispatch(getReferenceSizeSuccess(response.data));
            })
            .catch(error => {
                dispatch(getReferenceSizeFailed(error));
            });
    };
};

export const getReferenceSizeSuccess = (referenceSize) => {
    return {
        type: actionTypes.GET_REFERENCE_SIZE_SUCCESS,
        referenceSize
    }
}

export const getReferenceSizeFailed = (error) => {
    return {
        type: actionTypes.GET_REFERENCE_SIZE_FAILED,
        error
    }
}

export const saveReferenceSizeCloud = (referenceSize) => {
    return (dispatch) => {
        axios.put('/reference-size.json', referenceSize)
            .then(response => {
                dispatch(saveReferenceSizeSuccess(response.data));
            })
            .catch(error => {
                dispatch(saveReferenceSizeFailed(error));
            });
    };
}

export const updateActiveTeamIndex = (teamId) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_TEAM_INDEX,
        teamId
    }
}

export const addTeam = (teamData, teamId) => {
    return {
        type: actionTypes.ADD_TEAM,
        teamData,
        teamId
    }
}

export const deleteTeam = (teamId) => {
    return {
        type: actionTypes.DELETE_TEAM,
        teamId
    }
}

export const changeTeamName = (teamName) => {
    return {
        type: actionTypes.CHANGE_TEAM_NAME,
        teamName
    }
}

export const changeTeamColor = (teamColor) => {
    return {
        type: actionTypes.CHANGE_TEAM_COLOR,
        teamColor
    }
}