import React from 'react';

const FloorLine = (props) => {
    const points = props.points
        .map((el) => {
            return el.x + ' ' + el.y;
        })
        .join(',');

    const dots = props.points.map((el, index) => {
        return (
            <ellipse
                key={index}
                cx={el.x}
                cy={el.y}
                rx="3"
                ry="3"
                fill={props.color}
            />
        );
    });

    return (
        <g>
            <polyline
                stroke={props.color}
                strokeWidth="3"
                points={points}
                fill="none"
            />
            {dots}
        </g>
    );
};

export default FloorLine;
