import React from 'react';

const Content = (props) => {
    const scaleInfo = props.scale ? <p className="info-scale">schaal | 1 pixel : {props.scale.toFixed(2)} cm</p> : '';

    return (
    <div className="body">
        {props.children}

        {scaleInfo}
    </div>
    );
};

export default Content;
