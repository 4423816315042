import React from 'react';

import headerLogo from '../../../assets/img/header_logo.svg';

const Header = () => (
    <div className="header">
        <a href="/"><img
            className="header__logo"
            src={headerLogo}
            alt="FloorPlanGenerator"
        /></a>
    </div>
);

export default Header;
