import React from 'react';
import Aux from '../../hoc/Aux/Aux';
import infoIcon from '../../assets/img/info-icon.svg';

const SpaceNorm = (props) => {
    return (
        <Aux>
            <div className="space-norm">
                <div className="info">
                    <h3>Ruimtenormen bepalen</h3>
                    <p>Bepaal op dit blad de ruimtenormeringen</p>
                </div>

                <div className="info__block">
                    <h3>Oppervlakte per werkplek in m2</h3>
                    <p>
                        Voer hier de oppervlakte per werkplek in. Druk voor
                        normeringen op het informatieknopje
                    </p>
                    <div className="info__input">
                        <div className="info__input-element"> 
                            <input
                                type="text"
                                onChange={(e) => props.updateSurfaceHandler(e.target.value)}
                                value={props.spaceNorms ? props.spaceNorms.surfaceArea : 0}
                            />
                        </div>
                        <p className="info__unit">m<span className="squared">2</span></p>

                        <img className="custom-file-upload__info" onClick={props.onInfoClick} src={infoIcon} alt="info"/>
                    </div>
                </div>
                <div className="info__block">
                    <h3>Bepaal de flexfactor</h3>
                    <p>Bepaal de flexfactor (fte's per werkplek)</p>

                    <div className="info__input">
                        <div className="info__input-element">
                            <input
                                type="text"
                                onChange={(e) =>
                                    props.updateFlexFactorHandler(e.target.value)
                                }
                                value={props.spaceNorms ? props.spaceNorms.flexFactor : 0}
                            />
                        </div>
                        <p className="info__unit">per werplek</p>

                        <img className="custom-file-upload__info" onClick={props.onInfoClick} src={infoIcon} alt="info"/>
                    </div>
                </div>
            </div>
        </Aux>

    );
};

export default SpaceNorm;